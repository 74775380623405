const initialState = {
  defaultTableData: [],
  detailedIprFilterData: {},
  filterMetadata: [],
  filterTableData: [],
  filterValues: {},
  filtersClosed: true,
  isLoading: null,
  paging: {},
  searchResults: {
    _links: null,
    isLoading: null,
    page: null,
    values: []
  },
  searchTerm: ''
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER_LIST_FILTER':
      return Object.assign({}, state, {
        searchTerm: action.payload
      });
    case 'SET_METADATA':
      return Object.assign({}, state, {
        filterMetadata: action.payload
      });
    case 'SET_FILTER_TABLE_DATA':
      return Object.assign({}, state, {
        filterTableData: action.payload,
        isLoading: false
      });
    case 'SET_FILTER_TABLE_DATA_ITEMS':
      const filterTableData = state.filterTableData.map(tableData => {
        const item = action.payload.find(
          column => column.name === tableData.name
        );
        if (item) {
          return { ...tableData, ...item };
        }
        return tableData;
      });
      return {
        ...state,
        filterTableData
      };
    case 'RESET_FILTER_TABLE_DATA':
      return Object.assign({}, { filterTableData: [] });
    case 'CLEAR_VALUES_TABLE_DATA':
      return Object.assign({}, state, { filterValues: {} });
    case 'SET_VALUES_TABLE_DATA':
      return Object.assign({}, state, {
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: {
            _links: action.payload._links,
            items: action.payload._embedded
              ? action.payload._embedded.filters
              : [],
            name: action.payload.name,
            page: action.payload.page,
            totalElements: action.payload.page.totalElements,
            values: action.payload._embedded
              ? action.payload._embedded.filters
              : []
          }
        }
      });
    case 'SET_FILTER_SEARCH_RESULTS':
      return Object.assign({}, state, {
        searchResults: { ...state.searchResults, ...action.payload }
      });
    case 'SET_FILTER_SEARCH_RESULTS_LOADING':
      return Object.assign({}, state, {
        searchResults: { ...state.searchResults, isLoading: action.payload }
      });
    case 'SET_IS_LOADING_FILTER_DATA':
      return Object.assign({}, state, { isLoading: true });
    case 'UPDATE_FILTERS_CLOSED':
      return {
        ...state,
        filtersClosed: action.payload
      };
    case 'SET_DETAILED_IPR_FILTER_DATA':
      return Object.assign({}, state, {
        detailedIprFilterData: action.payload
      });
    default:
      return state;
  }
};

export default filters;
