import { mapModulesAsObjectsForSelect } from '../../Utils/brand-image';

const initialState = {
  amazonBrands: [],
  brandImages: [],
  complainants: {},
  copyrightTypes: [],
  copyrights: {},
  countries: [],
  csvTemplates: {},
  emailTemplates: {},
  formErrors: undefined,
  infringementDescriptions: {},
  ipRights: {},
  iprOffices: [],
  isLoading: false,
  isLoadingBrandImages: false,
  isLoadingCSVTemplates: false,
  isLoadingComplainants: false,
  isLoadingCopyrights: false,
  isLoadingDescriptions: false,
  isLoadingEmailTemplates: false,
  isLoadingPatents: false,
  isLoadingTrademarks: false,
  patents: {},
  selectedEnfCaseItem: null,
  selectedItems: [],
  states: [],
  trademarkTypes: [],
  trademarks: {}
};

const enfCase = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AMAZON_BRANDS':
      return Object.assign({}, state, {
        amazonBrands: action.payload
      });
    case 'SET_TRADEMARKS':
      let trademarks = {
        data: []
      };
      if (action.payload._embedded) {
        trademarks = {
          data: action.payload._embedded.trademarks,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        trademarks = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingTrademarks: false,
        trademarks: trademarks
      });
    case 'SET_PATENTS':
      let patents = {
        data: []
      };
      if (action.payload._embedded) {
        patents = {
          data: action.payload._embedded.patents,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        patents = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingPatents: false,
        patents
      });
    case 'SET_TRADEMARK_TYPES':
      return Object.assign({}, state, {
        isLoading: false,
        trademarkTypes: action.payload.types ? action.payload.types : []
      });
    case 'SET_TRADEMARK_OFFICES':
      return Object.assign({}, state, {
        iprOffices: action.payload.metadata ? action.payload.metadata : [],
        isLoading: false
      });
    case 'SET_COPYRIGHTS':
      let copyrights = {
        data: []
      };
      if (action.payload._embedded) {
        copyrights = {
          data: action.payload._embedded.copyrights,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        copyrights = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        copyrights: copyrights,
        isLoading: false,
        isLoadingCopyrights: false
      });
    case 'SET_COPYRIGHT_TYPES':
      return Object.assign({}, state, {
        copyrightTypes: action.payload.types ? action.payload.types : [],
        isLoading: false
      });
    case 'SET_COMPLAINANTS':
      let complainants = {
        data: []
      };
      if (action.payload._embedded) {
        complainants = {
          data: action.payload._embedded.complainant,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        complainants = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        complainants: complainants,
        isLoading: false,
        isLoadingComplainants: false
      });
    case 'SET_INFRINGEMENT_DESCRIPTIONS':
      let infringementDescriptions = {
        data: []
      };
      if (action.payload._embedded) {
        infringementDescriptions = {
          data: action.payload._embedded['infringement_descriptions'],
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        infringementDescriptions = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        infringementDescriptions: infringementDescriptions,
        isLoading: false,
        isLoadingDescriptions: false
      });
    case 'SET_EMAIL_TEMPLATES':
      let emailTemplates = {
        data: []
      };
      if (action.payload._embedded) {
        emailTemplates = {
          data: action.payload._embedded['email_template'],
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        emailTemplates = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        emailTemplates: emailTemplates,
        isLoading: false,
        isLoadingEmailTemplates: false
      });

    case 'SET_EMAIL_TEMPLATES_FOR_PRODUCT':
      let productEmailTemplates = {
        data: []
      };
      if (action.payload._embedded) {
        productEmailTemplates = {
          data: action.payload._embedded['email_template'],
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        productEmailTemplates = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        isLoading: false,
        productEmailTemplates: productEmailTemplates
      });

    case 'SET_EMAIL_TEMPLATE_TYPES_AND_FIELDS':
      return Object.assign({}, state, {
        emailTemplateTypesAndFields: action.payload
      });

    case 'SET_ENFORCEMENT_SETTINGS':
      return Object.assign({}, state, {
        enforcementSettings: action.payload._embedded['platform-settings']
      });

    case 'SET_CSV_TEMPLATES':
      let csvTemplates = {
        data: []
      };
      if (action.payload?._embedded) {
        csvTemplates = {
          data: action.payload._embedded['email_template'],
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload?.length) {
        csvTemplates = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        csvTemplates: csvTemplates,
        isLoading: false,
        isLoadingCSVTemplates: false
      });

    case 'SET_IP_RIGHTS':
      let ipRights = {
        data: []
      };
      if (action.payload._embedded) {
        ipRights = {
          data: action.payload._embedded.ipRights,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        ipRights = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        ipRights: ipRights,
        isLoading: false
      });
    case 'SET_SELECTED_ENF_CASE_ITEM':
      if (action.payload?.searchOn) {
        action.payload.searchOn = action.payload?.searchOn.map(module =>
          mapModulesAsObjectsForSelect(module)
        );
      }
      if (action.payload?.detectOn) {
        action.payload.detectOn = action.payload?.detectOn.map(module =>
          mapModulesAsObjectsForSelect(module)
        );
      }

      return Object.assign({}, state, {
        selectedEnfCaseItem: action.payload ? action.payload : null
      });
    case 'SET_COUNTRIES':
      return Object.assign({}, state, { countries: action.payload });
    case 'SET_IPRCOUNTRIES':
      return Object.assign({}, state, { iprCountries: action.payload });
    case 'SET_STATES':
      return Object.assign({}, state, { states: action.payload });
    case 'SET_IS_LOADING_ENF_CASE':
      return Object.assign({}, state, { isLoading: action.payload });
    case 'SET_IS_LOADING_COMPLAINANTS':
      return Object.assign({}, state, {
        isLoadingComplainants: true
      });
    case 'SET_IS_LOADING_DESCRIPTIONS':
      return Object.assign({}, state, {
        isLoadingDescriptions: true
      });
    case 'SET_IS_LOADING_TRADEMARKS':
      return Object.assign({}, state, {
        isLoadingTrademarks: true
      });
    case 'SET_IS_LOADING_COPYRIGHTS':
      return Object.assign({}, state, {
        isLoadingCopyrights: true
      });
    case 'SET_IS_LOADING_Patents':
      return Object.assign({}, state, {
        isLoadingPatents: false
      });
    case 'SET_IS_LOADING_EMAIL_TEMPLATES':
      return Object.assign({}, state, {
        isLoadingEmailTemplates: true
      });
    case 'SET_IS_LOADING_CSV_TEMPLATES':
      return Object.assign({}, state, {
        isLoadingCSVTemplates: true
      });
    case 'SET_FORM_ERRORS':
      const errorObj = {};
      if (action.payload?.errors?.length) {
        action.payload.errors.map(error => {
          return (errorObj[error.field] =
            error.defaultMessage.charAt(0).toUpperCase() +
            error.defaultMessage.substring(1));
        });
      }

      if (!Object.keys(errorObj).length) {
        let errorString;
        if (action.payload) {
          errorString = action.payload.errors?.length
            ? action.payload.errors
                .map(error => {
                  return `${error.field} - ${error.defaultMessage}`;
                })
                .join('<br />')
            : action.payload.message;
        }
        return Object.assign({}, state, { formErrors: errorString });
      }
      return Object.assign({}, state, { formErrors: errorObj });
    case 'SET_TWITTER_SETUP_FIELDS':
      if (!action.payload) {
        return;
      }
      const copyrightFields = Object.assign(
        {},
        action.payload.find(obj => {
          return obj?.data.copyrights ? obj.data.copyrights : [];
        }).data
      );
      copyrightFields.options = [
        { label: 'Tweet', value: 'TWEET' },
        { label: 'Profile Image', value: 'PROFILE_IMAGE' }
      ];

      const trademarkFields = Object.assign(
        {},
        action.payload.find(obj => {
          return obj?.data.trademarks ? obj.data.trademarks : [];
        }).data
      );

      const twitterFields = {};
      twitterFields.copyrightFields = copyrightFields;
      twitterFields.infringingTrademarkFields = trademarkFields;
      delete twitterFields.infringingTrademarkFields.impersonationOption;
      delete twitterFields.infringingTrademarkFields.impersonationSubOption;
      twitterFields.impersonatingTrademarkFields = trademarkFields;
      twitterFields.impersonatingTrademarkFields.trademarkReportType =
        'IMPERSONATING';
      twitterFields.impersonatingTrademarkFields.options = [
        { label: 'Name', value: 'NAME' },
        { label: 'Image', value: 'IMAGE' },
        { label: 'Contact Info', value: 'CONTACT_INFO' },
        { label: 'Content', value: 'CONTENT' }
      ];
      const impersonationCoreOptions = [
        { label: 'Tweet', value: 'TWEET' },
        { label: 'Name', value: 'NAME' },
        { label: 'Username', value: 'USERNAME' },
        { label: 'Bio', value: 'BIO' },
        { label: 'Location', value: 'LOCATION' },
        { label: 'Image', value: 'IMAGE' },
        { label: 'Url', value: 'URL' }
      ];
      twitterFields.impersonatingTrademarkFields.subOptions = {
        CONTACT_INFO: impersonationCoreOptions,

        CONTENT: impersonationCoreOptions,
        IMAGE: [
          { label: 'Profile Photo', value: 'PROFILE_PHOTO' },
          { label: 'Profile Header', value: 'PROFILE_HEADER' },
          { label: 'Profile Background', value: 'PROFILE_BACKGROUND' },
          { label: 'Tweet', value: 'TWEET' }
        ],
        NAME: impersonationCoreOptions
      };
      return Object.assign({}, state, {
        twitterSetupFields: twitterFields
      });
    case 'SET_SELECTED':
      return Object.assign({}, state, { selectedItems: action.payload });
    case 'SET_BRAND_IMAGES':
      action.payload?._embedded?.brand_image.map(image => {
        image.detectOnModules = image?.detectOn?.map(module =>
          mapModulesAsObjectsForSelect(module)
        );
        image.detectOn = image.detectOn?.length ? true : false;
        image.searchOnModules = image?.searchOn?.map(module =>
          mapModulesAsObjectsForSelect(module)
        );
        image.searchOn = image.searchOn?.length ? true : false;
      });

      return Object.assign({}, state, {
        brandImages: action.payload
      });
    case 'SET_BRAND_IMAGE_TYPES':
      return Object.assign({}, state, {
        brandImages: action.payload?.types
      });
    case 'SET_BRAND_IMAGE_BRANDS':
      return Object.assign({}, state, {
        brandImageBrands: action.payload?._embedded?.brands
      });
    case 'SET_IS_LOADING_BRAND_IMAGES':
      return Object.assign({}, state, {
        isLoadingBrandImages: action.payload
      });
    case 'SET_BRAND_IMAGE_MODEL_CLASS_OPTIONS':
      return Object.assign({}, state, {
        modelClassOptions: action.payload
      });
    default:
      return state;
  }
};

export const selectAmazonBrands = state => state.enfCase.amazonBrands;

// eslint-disable-next-line import/no-default-export
export default enfCase;
